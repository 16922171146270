import { ICreatedUpdatedBy } from '@/bundles/App/interfaces/ICreatedUpdatedBy';
import { IPermissions } from '@/bundles/App/interfaces/IPermissions';
import { OccupierRentType } from '@/bundles/Occupier/types';
import { Nullable } from '@/utils/types';
import { IReportSizeRangeItem } from '@/bundles/Reports/interfaces';

export interface IAccounting {
  accounting_method: string;
  fiscalYearEnd: {
    day: string|number|null;
    month: string|number|null;
  };
  wires: {
    account_number: string|number|null;
    bank_name: string|null;
    routing_number: string|number|null;
    custom_content: Nullable<string>;
  };
  show_broker_net: boolean;
  show_total_commission: boolean;
  show_banking_instructions: boolean;
}

export interface IReportSettingsItem {
  name: string;
  value: string;
}

export interface IReportSettings {
  property_types: IReportSettingsItem[];
  property_covers: IReportSettingsItem[];
  disclaimer?: string;
}

export interface ICustomNavLink {
  title: string;
  url: string;
  icon: string;
}

export interface IInvoiceBilling {
  _key: Nullable<string>;
  name: Nullable<string>;
  email: Nullable<string>;
}

// TODO: rework model with new approach
export default class AccountSettings {
  _id?: string;
  _key?: string;
  email_sender!: string;
  email_footer!: string;
  pressReleaseDefaults!: { [key: string]: any };
  emailBlastDefaults!: { [key: string]: any };
  customNavigationLinks!: ICustomNavLink[];
  accounting_method?: string;
  accounting_fiscal_year_ending?: string;
  press_release_sets?: string[];
  syndication_set?: string;
  installer_set?: string;
  verification_send_message?: string;
  verification_response_message?: string;
  properties?: { [key: string]: any };
  map?: { [key: string]: any }
  accounting?: IAccounting;
  created?: ICreatedUpdatedBy;
  updated?: ICreatedUpdatedBy;
  report_settings?: IReportSettings;
  verification_listing_due?: number;
  occupier_default_rent_type?: OccupierRentType;
  permissions?: IPermissions;
  invoice_billing?: IInvoiceBilling;
  invoice_billing_custom?: Omit<IInvoiceBilling, '_key'>;
  report_ranges?: IReportSizeRangeItem[];
  default_invoice_message?: string;
  company_website?: Nullable<string>;
  max_export_records?: number;
  limit_exports?: boolean;
  allow_procurement?: boolean;
  allow_edit_requests?: boolean;
  date_display_format?: string;
  date_picker_format?: string;
  use_types_by_property_type?: boolean;
}
